import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from './icon.component';
import { ColorThemeModule } from '../shared/color-theme/color-theme.module';


@NgModule({
  declarations: [
    IconComponent
  ],
  imports: [
    CommonModule,
    ColorThemeModule
  ],
  exports: [
    IconComponent,
    ColorThemeModule
  ]
})
export class IconModule { }
